<template>
  <ContentBox :nav-list="navList">
    <el-form
      ref="form"
      label-suffix="："
      label-width="100px"
      :inline="true"
      class="demo-form-inline"
      :model="values"
      :rules="rules"
      :label-position="labelPosition"
    >
      <Card>
        <template v-slot:header>{{ $t('c421ebe') }}</template>
        <el-row>
          <el-col :span="12">
            <el-form-item required :label="$t('90c7bae')" prop="name">
              <el-input
                v-model="values.name"
                :placeholder="$t('41f0b1a')"
                style="width: 360px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12" style="text-align: left">
            <el-form-item :label="$t('ec6372d')">
              <el-input
                v-model="values.unit"
                :placeholder="$t('0b21200')"
                style="width: 360px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item required :label="$t('db8d921')" prop="phone">
              <el-input
                v-model="values.phone"
                :placeholder="$t('18b2b78')"
                style="width: 360px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12" style="text-align: left">
            <el-form-item required :label="$t('5bfb52e')" prop="email">
              <el-input
                v-model="values.email"
                :placeholder="$t('133bec8')"
                style="width: 360px"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </Card>

      <Card style="margin-top: 20px">
        <template v-slot:header>{{ $t('10d479d') }}</template>
        <el-row>
          <el-col :span="12">
            <el-form-item label-width="170px" :label="$t('f7f1545')">
              <el-select
                v-model="values.country"
                :placeholder="$t('10d479d')"
                style="width: 290px"
              >
                <el-option
                  v-for="(option, index) in country"
                  :key="index"
                  :label="option.name"
                  :value="option.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="text-align: left">
            <el-form-item label-width="170px" :label="$t('cda23fb')">
              <el-select
                v-model="values.departmentId"
                :placeholder="$t('b358113')"
                style="width: 360px"
              >
                <el-option
                  v-for="(option, index) in department"
                  :key="index"
                  :label="option.name"
                  :value="option.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label-width="130px" :label="$t('98a7c9a')">
              <el-input-number
                v-model="values.expectedCost"
                :placeholder="$t('b55cec5')"
                style="width: 285px"
                :min="0"
                :max="99999999"
                :precision="2"
                :controls="false"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12" style="text-align: left">
            <el-form-item label-width="190px" :label="$t('4c2e103')">
              <el-input-number
                v-model="values.expectedTime"
                :placeholder="$t('7f94271')"
                style="width: 218px"
                :min="1"
                :max="99999999"
                :precision="0"
                :controls="false"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </Card>

      <Card style="margin-top: 20px">
        <template v-slot:header>{{ $t('6713619') }}</template>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label-width="250px"
              :label="$t('8a0f7e5')"
            >
              <el-radio-group v-model="values.isItInvolved">
                <el-radio :label="true">{{ $t('99df41f') }}</el-radio>
                <el-radio :label="false">{{ $t('4e21644') }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label-width="250px" :label="$t('a38c649')">
              <el-radio-group v-model="values.foreignInvestment">
                <el-radio :label="true">{{ $t('99df41f') }}</el-radio>
                <el-radio :label="false">{{ $t('4e21644') }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label-width="290px"
              :label="$t('eb2a4b2')"
            >
              <el-radio-group v-model="values.needNotarization">
                <el-radio :label="true">{{ $t('99df41f') }}</el-radio>
                <el-radio :label="false">{{ $t('4e21644') }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label-width="250px" :label="$t('6f60d9e')">
              <el-radio-group v-model="values.needTranslation">
                <el-radio :label="true">{{ $t('99df41f') }}</el-radio>
                <el-radio :label="false">{{ $t('4e21644') }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label-width="130px"
              required
              :label="$t('06618ba')"
              prop="caseDescription"
            >
              <el-input
                v-model="values.caseDescription"
                :rows="5"
                type="textarea"
                :placeholder="$t('65da452')"
                style="width: 1016px"
                maxlength="500"
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label-width="130px"
              required
              :label="$t('eb15d1f')"
              prop="demandDescription"
            >
              <el-input
                v-model="values.demandDescription"
                :rows="6"
                type="textarea"
                :placeholder="$t('88dabd3')"
                style="width: 1016px"
                maxlength="1000"
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <el-col :span="24" style="display: flex">
            <el-form-item :label="$t('b264a36')">
              <el-upload
                class="uploadenclosure"
                :headers="headers"
                :action="actionUrl"
                :multiple="false"
                :limit="1"
                :file-list="fileList"
                :on-success="uploadSuccess"
                :on-remove="handleRemove"
                :before-upload="beforeUpload"
                :accept="docMemeType"
              >
                <el-button
                  class="addButton"
                ><i
                  class="icon el-icon-upload2"
                  style="margin-right: 8px"
                ></i>{{ $t('60d62d8') }}</el-button>
              </el-upload>
            </el-form-item>
            <div class="el-upload__tip">
              {{ $t('1765d28') }}
            </div>
          </el-col>
        </el-row>
      </Card>
      <div class="button-box">
        <el-button class="button-ugly" @click="handleSubmit">
          {{ $t('8479db1') }}
        </el-button>
        <el-button class="button-default" @click="back"> {{ $t('5b9fcb6') }} </el-button>
      </div>
    </el-form>
  </ContentBox>
</template>

<script>
import validator, { docMemeType } from '@/utils/validation'

import { mapGetters, mapActions } from "vuex"
import { Message } from "element-ui"
import Card from "@/components/Card"
import ContentBox from "@/components/ContentBox"
import { getToken } from "@/utils/auth"
import api from "@/api/legal"

const actionUrl = "/api/frLegalPlatform/common/qiniu/upload"
export default {
  name: "Apply",
  components: {
    Card,
    ContentBox,
  },
  data() {
    return {
      headers: {
        accessToken: getToken(),
      },
      fileList: [],
      actionUrl,
      docMemeType,
      labelPosition: localStorage.getItem("lang") === "zh" ? "left" : "top",
      navList: [
        { path: "/legal/query", name: "82bcb8c" },
        { path: "", name: "6f98b8c" },
      ],
      values: {
        caseDescription: undefined,
        country: undefined,
        demandDescription: undefined,
        departmentId: undefined,
        email: undefined,
        expectedCost: undefined,
        expectedTime: undefined,
        fileName: undefined,
        annex: undefined,
        foreignInvestment: undefined,
        isItInvolved: undefined,
        name: "",
        needNotarization: undefined,
        needTranslation: undefined,
        phone: undefined,
        unit: undefined,
      },
    }
  },
  computed: {
    ...mapGetters(["dictionaries"]),
    rules() {
      return {
        name: [
          { required: true, message: this.$t('41f0b1a') },
          { validator: validator.name },
        ],
        unit: undefined,
        phone: [
          { required: true, message: this.$t('18b2b78') },
          { validator: validator.contactNumber },
        ],
        email: [
          { required: true, message: this.$t('133bec8') },
          { validator: validator.email },
        ],
        caseDescription: { required: true, message: this.$t('65da452') },
        demandDescription: [{ required: true, message: this.$t('88dabd3') }],
      }
    },
    country() {
      const country = this.dictionaries["COUNTRY"]
      return country ? country.list : []
    },
    department() {
      const department = this.dictionaries["DEPARTMENT_INVOLVED"]
      return department ? department.list : []
    },
  },
  beforeUpdate() {
    this.labelPosition = localStorage.getItem("lang") === "zh" ? "left" : "top"
  },
  mounted() {
    this.getDictionary({ type: "COUNTRY" })
    this.getDictionary({ type: "DEPARTMENT_INVOLVED" })
  },
  methods: {
    ...mapActions(["getDictionary"]),
    handleSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          api.add(this.values).then((res) => {
            if (res) {
              Message({
                message: "提交成功",
                type: "success",
                duration: 5 * 1000,
                showClose: true,
              })
              this.$router.push({
                path: `/legal/query`,
              })
            }
          })
        }
      })
    },
    back() {
      this.$router.push({
        path: `/legal/query`,
      })
    },
    uploadSuccess(response, file, fileList) {
      // console.log(response, file, fileList);
      this.values.annex = response.data.url
      this.values.fileName = response.data.fileName
    },
    handleRemove() {
      this.values.annex = undefined
      this.values.fileName = undefined
    },
    beforeUpload(file) {
      // const isJPG = file.type === 'image/jpeg'
      const isLt10M = file.size / 1024 / 1024 < 10

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!')
      // }
      if (!isLt10M) {
        this.$message.error(this.$t('6bf96ed').toString())
      }
      return isLt10M
    },
  },
}
</script>

<style scoped lang="less">
.button-box {
  display: flex;
  justify-content: center;
  padding: 20px;
  /deep/ .el-button + .el-button {
    margin-left: 40px;
  }
  button {
    width: 180px;
    height: 40px;
  }
  .button-ugly {
    color: white;
    background: linear-gradient(#05d0fe, #2c6fe8);
  }
  .button-default {
    color: rgba(0, 164, 255, 1);
    border-color: rgba(0, 164, 255, 1);
  }
}
.el-upload__tip {
  margin-left: 16px;
  line-height: 30px;
}
.demo-form-inline {
  /deep/ .el-input-number .el-input__inner {
    text-align: left;
  }
}
</style>
